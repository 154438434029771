<template>
	<div class="log_in">
		<div class="van-nav-tb">
			<van-nav-bar
				title="登录"
				left-text=""
				left-arrow
			/>
		</div>
		<img class="btwz" src="@/assets/gbjywenz.png" alt="" />
		<div class="dlbjqy">
			<h1>用户登录</h1>
			<van-form>
				<div style="height: 10px;"></div>
				<div class="yhm">
					<img src="@/assets/zhanghao.png" alt="" />
					<van-field
						v-model="loginName"
						name="用户名"
						placeholder="请输入您用户名" type="text"
					/>
				</div>
				<div style="height: 20px;"></div>
				<div class="mima">
					<img src="@/assets/mima.png" alt="" />
					<van-field
						v-model="password"
						name="密码"
						placeholder="请输入密码" type="password"
					/>
				</div>
				<router-link to="/xgmm">
					<a href="#">忘记密码？</a>
				</router-link>
				<div style="height: 10px;"></div>
				<div style="margin: 16px;">
					<van-button round block type="info" @click="login">登&nbsp;&nbsp;&nbsp;&nbsp;录</van-button>
				</div>
			</van-form>
		</div>
	</div>
</template>
<script>
	import { codeLogin } from '@/api/login'
	// import { setToken } from '@/store/modules/user'
	// import { mapMutations } from 'vuex'
    export default {
		name: 'LoginIndex',
		data() {
			return {
				loginName: '',
				password: '',
			}
		},
		methods: {
			// ...mapMutations('user',['setToken']),
			validFn () {
				if (this.loginName == "") {
					this.$toast('用户名不能为空')
					return false
				}
				if (this.password == "") {
					this.$toast('密码不能为空')
					return false
				}
				return true
			},
			async login () {
				if (!this.validFn()){
					return
				}

				const res = await codeLogin(this.loginName, this.password)
				// console.log(res.token,99999)
				// this.setToken(res.token)
				// 由于强制刷新之后，vuex里面的数据就会消失，所以还要进行本地存储
				// localStorage.setItem('token',res.token)
				if(res.code==200){
					// 登录成功，显示成功消息或跳转到其他页面
					this.$store.commit('user/setToken',res.token)
					this.$toast('登录成功')
					// 进行判断，看地址栏有无回跳地址
					// 1. 如果有   => 说明是其他页面，拦截到登录来的，需要回跳
					// 2. 如果没有 => 正常去首页
					const url = this.$route.query.backUrl || '/';
					this.$router.replace(url);
				}else{
					// 其他消息，根据msg信息进行相应处理
					this.$toast(res.msg);
				}
			}
		},
    }
</script>
<style scoped>
.log_in{
	width: 100%;
	height: 100vh;
	background: url("@/assets/dlbj.png") no-repeat;
	background-size: 100% 100%;
	display: flex;
	flex-direction: column;
}
.btwz{
	width: 227px;
	height: 83px;
	margin: 50px auto;
}
.dlbjqy{
	width: 295px;
	height: 275px;
	background: rgba(255,255,255,0.15);
	border-radius: 10px;
	margin: 0 auto;
}
.dlbjqy h1{
	width: auto;
	font-size: 18px;
	font-family: Microsoft YaHei;
	font-weight: bold;
	color: #FFFFFF;
	line-height: 21px;
	margin-top: 20px;
	text-align: center;
}
.dlbjqy a{
	width: 225px;
	height: 23px;
	font-size: 14px;
	font-family: Microsoft YaHei;
	font-weight: 400;
	color: #FFFFFF;
	line-height: 35px;
	margin: 0 auto;
	text-align: right;
	display: block;
}
.yhm{
	width: 225px;
	height: 37px;
	background: #FFFFFF;
	border-radius: 10px;
	display: flex;
	flex-direction: row;
	align-items: center;
	margin: 0 auto;
}
.yhm img{
	width: 20px;
	height: 22px;
	margin-left: 10px;
}
.mima{
	width: 225px;
	height: 37px;
	background: #FFFFFF;
	border-radius: 10px;
	display: flex;
	flex-direction: row;
	align-items: center;
	margin: 0 auto;
}
.mima img{
	width: 20px;
	height: 22px;
	margin-left: 10px;
}
/deep/.van-cell{
	background-color: rgba(255, 255, 255, 0.0);
}
/deep/#txtput .ivu-input-group-prepend {
	background-color: #f8f8f9;
	border: none;
	border-radius: 4px;
}
/deep/#txtput1 .ivu-form-item-content{
	display: flex;
	flex-direction: row;
}
.van-button::before{
	width: 50%;
}
.van-button--info{
	width: 157px;
	height: 35px;
	font-size: 18px;
	font-family: Microsoft YaHei;
	font-weight: bold;
	color: #B50002;
	line-height: 21px;
	background-color: #FFFFFF;
	border: none;
	margin: 0 auto;
}
</style>
