import request from '@/utils/request'

// 获取我的数据
export const getXscxData = (year, learnHour) => {
	return request.get(`/client/user/classhour/my/hourSummaryList?year=${year}&learnHour=${learnHour}`, {
	})
}

export const getYcjpxbData = (beginStartTime, endStartTime) => {
	return request.get(`/client/user/class/list?beginStartTime=${beginStartTime}&endStartTime=${endStartTime}`, {
	})
}
